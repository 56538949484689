import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import Seo from "../components/seo";
import { Header, SubHeader, Body } from "../components/Typography";
import { SiteContent, TextImageBlock } from "../components/Layouts";
import { Colors } from "../theme";
import { CommercialData } from "../data/commercial";
import "../theme/css/animations.css";
import IndustryHero from "../components/Heros/IndustryHero";


const CommercialPageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  min-height: 1200px;
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 700px;

  @media only screen and (max-width: 780px) {
    min-height: 500px;
  }
`;

const CommercialPage = () => {

  let { hero, textblocks } = CommercialData;

  return (
    <CommercialPageWrapper>
      <Seo title="VASTech Solutions | Commercial Video, Audio, Security, Technology — Serving WNY & Beyond" />
      <IndustryHero data={hero} />

      <SiteContent column>
        {textblocks.map((data, index) => {
          return (
            <TextImageBlock data={data} key={'block ' + index} index={index}  />
          );
        })}
      </SiteContent>
    </CommercialPageWrapper>
  );
}

export default CommercialPage;
